import { IonRow } from '@ionic/react';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { BRAND_NAME } from '../../constants/Branding';
import './Copyright.scss';

const Copyright: React.FC = (props) => {
  return (
    <IonRow className="copyright-ctn">
      <div className="web-view">Copyright © 2022 | All rights reserved, {" "} <NavLink className="copyright-link" to="/responsible-gambling">Rules and Regulations</NavLink></div>
      <div className="mob-view d-block"><div className='d-block'>Copyright © 2022 | All rights reserved</div><div className='d-block rules-text'><NavLink className="copyright-link" to="/responsible-gambling">Rules and Regulations</NavLink></div></div>
    </IonRow>
  );
};

export default Copyright;
