import React, { useState, useEffect, useCallback } from 'react';
import { IonImg, IonButton, IonSpinner } from '@ionic/react';

import './MfaForm.scss';
import API from '../../api';

type MfaFormProps = {
  closeMfaFormModal: () => void;
  mfaStatusChangeHandler: (status: boolean) => void;
};

const MfaForm: React.FC<MfaFormProps> = (props) => {
  const [qrCodeSrc, setQrCodeSrc] = useState<string>(null);
  const [msg, setMsg] = useState<string>(null);
  const [errMsg, setErrMsg] = useState<string>(null);

  const { closeMfaFormModal, mfaStatusChangeHandler } = props;

  const getQRCode = useCallback(async () => {
    const mfaResponse = await API.get('/mfa', {
      headers: {
        Authorization: sessionStorage.getItem('jwt_token'),
      },
    });
    if (!mfaResponse.data.success) {
      const username = JSON.parse(
        window.atob(sessionStorage.getItem('jwt_token').split('.')[1])
      ).sub;
      const response = await API.get(`/mfa/generate/${username}`, {
        responseType: 'blob',
        headers: {
          Authorization: sessionStorage.getItem('jwt_token'),
        },
      });
      let imgUrl = URL.createObjectURL(response.data);
      setQrCodeSrc(imgUrl);
    } else {
      setErrMsg('MFA already set up.');
    }
  }, []);

  const enableMfaHandler = async () => {
    try {
      await API.put(
        '/mfa',
        { enable: true },
        {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
        }
      );
      setMsg('MFA enabled');
      mfaStatusChangeHandler(true);
    } catch (ex) {
      console.log(ex);
      setMsg('Something went wrong');
    }
  };

  const disableMfaHandler = async () => {
    try {
      await API.put(
        '/mfa',
        { enable: false },
        {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
        }
      );
      closeMfaFormModal();
    } catch (ex) {
      console.log(ex);
    }
  };

  useEffect(() => {
    getQRCode();
  }, [getQRCode]);

  return (
    <div className="mfa-ctn">
      {qrCodeSrc ? (
        <>
          <IonImg src={qrCodeSrc}></IonImg>
          {msg ? <div className="mfa-msg">{msg}</div> : null}
          <IonButton
            color="primary"
            class="scanned-btn"
            onClick={enableMfaHandler}
          >
            I have scanned the QR code
          </IonButton>
        </>
      ) : errMsg ? (
        <div className="mfa-err-msg">
          {errMsg}{' '}
          <span className="deactivate-link" onClick={disableMfaHandler}>
            Disable?
          </span>
        </div>
      ) : (
        <IonSpinner name="lines" class="mfa-spinner"></IonSpinner>
      )}
    </div>
  );
};

export default MfaForm;
