// Web Banners
import Banner from '../assets/images/common/Banner/casinobanner.png';
// Mobile banners

// casino filter icons
import all from '../assets/images/casino/casino-tabs/all.svg';
import india from '../assets/images/casino/casino-tabs/india.svg';
import poker from '../assets/images/casino/casino-tabs/poker.svg';
import roulette from '../assets/images/casino/casino-tabs/roulate.svg';
import blackjack from '../assets/images/casino/casino-tabs/blackjack.svg';
import other from '../assets/images/casino/casino-tabs/other.svg';

import Img1 from '../assets/images/Banners/R1.webp';
import Img2 from '../assets/images/Banners/R2.webp';

// import Img2 from '../assets/images/Banners/R2.webp';
import Img3 from '../assets/images/Banners/R3.webp';
import Img4 from '../assets/images/Banners/R4.webp';
import Img5 from '../assets/images/Banners/R5.png';
import Img6 from '../assets/images/Banners/R6.webp';
import Img7 from '../assets/images/Banners/R7.webp';
import Img8 from '../assets/images/Banners/ComingSoonWeb2.png';
import Img21 from '../assets/images/Banners/R9.webp';


import Mob1 from '../assets/images/Banners/Frame 325.png';
import Mob2 from '../assets/images/Banners/Frame 326.png';
import Mob3 from '../assets/images/Banners/Frame 327.png';
import Mob4 from '../assets/images/Banners/Frame 328.png';
import Mob5 from '../assets/images/Banners/Frame 329.png';
import Mob6 from '../assets/images/Banners/Frame 330.png';
import Mob7 from '../assets/images/Banners/Frame 331.png';
import Mob8 from '../assets/images/Banners/Frame 332.png';

import Img9 from '../assets/images/casino/providers/9.jpg';
import Img10 from '../assets/images/casino/providers/10.jpg';
// import Img12 from '../assets/images/casino/providers/12.jpg';



import Img11 from '../assets/images/casino/providers/p1.jpg';
import Img12 from '../assets/images/casino/providers/p2.jpg';
import Img13 from '../assets/images/casino/providers/p3.jpg';
import Img14 from '../assets/images/casino/providers/p4.jpg';
import Img15 from '../assets/images/casino/providers/p5.jpg';
import Img16 from '../assets/images/casino/providers/p6.jpg';
import Img17 from '../assets/images/casino/providers/p7.jpg';
import Img18 from '../assets/images/casino/providers/p8.jpg';
import Img19 from '../assets/images/casino/providers/p9.jpg';
import Img20 from '../assets/images/casino/providers/p10.jpg';

import Ezugi1 from "../assets/images/Banners/Ezugi1.webp";
import Ezugi2 from "../assets/images/Banners/Ezugi2.webp";
import Ezugi3 from "../assets/images/Banners/Ezugi3.webp";
import Ezugi4 from "../assets/images/Banners/Ezugi4.webp";
import Ezugi5 from "../assets/images/Banners/Ezugi5.webp";
import Ezugi6 from "../assets/images/Banners/Ezugi6.webp";

import TwoCardsOneDay from '../assets/images/virtualCasino/2 Card Teen patti one day.jpg';
import TwoCards from '../assets/images/virtualCasino/2 Card Teen patti.jpg';
import Cards3 from '../assets/images/virtualCasino/3 card judgement.jpg';
import Cards32 from '../assets/images/virtualCasino/32 Cards.jpg';
import SevenUpDown from '../assets/images/virtualCasino/7 up & down virtual.jpg';
import Bollywood from '../assets/images/virtualCasino/Bollywood Casino.jpg';
import Baccarat from '../assets/images/virtualCasino/Baccarat.jpg';
import AmarAkbar from '../assets/images/virtualCasino/Amar Akbar Anthony.jpg';
import FootBall from '../assets/images/virtualCasino/Foot Ball Studio.jpg';
import DusDum from '../assets/images/virtualCasino/Dus Ka Dum.jpg';
import DTigerVirtual from '../assets/images/virtualCasino/Dragon Tiger virtual.jpg';
import DTigerOneDay from '../assets/images/virtualCasino/Dragon Tiger one day.jpg';
import DTigerLion from '../assets/images/virtualCasino/Dragon Tiger Lion.jpg';
import Cricket from '../assets/images/virtualCasino/Cricket War.jpg';
import CasinoWar from '../assets/images/virtualCasino/Casino War.jpg';
import CasinoHoldem from '../assets/images/virtualCasino/Casino Holdem.jpg';
import Teenpatti from '../assets/images/virtualCasino/Teenpatti.jpg';
import TeenpattiOneDay from '../assets/images/virtualCasino/Teen Patti one day.jpg';
import TeenpattiMuflis from '../assets/images/virtualCasino/Teen Patti Muflis.jpg';
import SuperOver from '../assets/images/virtualCasino/Super Over one day.jpg';
import Poker from '../assets/images/virtualCasino/Poker.jpg';
import PokerOneDay from '../assets/images/virtualCasino/Poker one day.jpg';
import Lottery from '../assets/images/virtualCasino/Lottery.jpg';
import HiLo from '../assets/images/virtualCasino/Hi Lo.jpg';
import Worli from '../assets/images/virtualCasino/Worli Matka.jpg';
import Trio from '../assets/images/virtualCasino/Trio.jpg';
import TheTrap from '../assets/images/virtualCasino/The Trap.jpg';

import F1 from "../assets/images/liveCasino/Frame 309.png"
import F2 from "../assets/images/liveCasino/Frame 310.png"
import F3 from "../assets/images/liveCasino/Frame 311.png"
import F4 from "../assets/images/liveCasino/Frame 312.png"
import F5 from "../assets/images/liveCasino/Frame 313.png"
import F6 from "../assets/images/liveCasino/Frame 314.png"
import F7 from "../assets/images/liveCasino/Frame 315.png"
import F8 from "../assets/images/liveCasino/Frame 316.png"
import F9 from "../assets/images/liveCasino/Frame 317.png"
import F10 from "../assets/images/liveCasino/Frame 318.png"
import F11 from "../assets/images/liveCasino/Frame 319.png"
import F12 from "../assets/images/liveCasino/Frame 320.png"
import F13 from "../assets/images/liveCasino/Frame 321.png"
import F14 from "../assets/images/liveCasino/Frame 322.png"
import F15 from "../assets/images/liveCasino/Frame 323.png"
import F16 from "../assets/images/liveCasino/Frame 324.png"

export const CASINO_BANNERS = [
  {
    image: Banner,
    text: 'Roulette',
  },
];

export const CASINO_FILTERS = [
  {
    casinoIcon: all,
    text: 'all',
    id: '0',
  },
  {
    casinoIcon: india,
    text: 'india',
    id: '1',
  },
  {
    casinoIcon: roulette,
    text: 'roulette',
    id: '2',
  },
  {
    casinoIcon: poker,
    text: 'poker',
    id: '3',
  },
  {
    casinoIcon: blackjack,
    text: 'blackjack',
    id: '4',
  },
  {
    casinoIcon: other,
    text: 'other',
    id: '5',
  },
];

export const CASINO_SlOTS_NAV_PROVIDERS = [
  {
    image: Img11,
    text: 'Laser',
  },
  {
    image: Img12,
    text: 'game burger',
    url: '/casino',
  },
  // {
  //   image: Img2,
  //   text: 'Evolution',
  //   url: '/casino/Evolution Gaming',
  // },
  {
    image: Img13,
    text: 'Betsoft',
    url: '/casino',
  },
  {
    image: Img14,
    text: 'AEsexy',
    url: '/casino/AE',
  },
  {
    image: Img15,
    text: 'blue print',
    url: '/casino',
  },
  {
    image: Img16,
    text: 'Super Spade Gaming',
    url: '/casino/Super Spade Gaming',
  },
  {
    image: Img17,
    text: 'Super Spade Gaming',
    url: '/casino/Super Spade Gaming',
  },
  {
    image: Img18,
    text: 'Alchemy',
    url: '/casino',
  },
  {
    image: Img19,
    text: 'Casino',
    url: '/casino',
  },
  {
    image: Img20,
    text: 'blue guru',
    url: '/casino/JiLi Gaming',
  },

  // {
  //   image: Img12,
  //   text: 'jili',
  //   url: '/casino/JiLi Gaming',
  // },
];

export const EZUGI_PROVIDERS = [
  {
    publicUrl: Ezugi1,
    title: 'Ezugi',
  },
  {
    publicUrl: Ezugi2,
    title: 'Ezugi',
  },
  {
    publicUrl: Ezugi3,
    title: 'Ezugi',
  },
  {
    publicUrl: Ezugi4,
    title: 'Ezugi',
  },
  {
    publicUrl: Ezugi5,
    title: 'Ezugi',
  },
  {
    publicUrl: Ezugi6,
    title: 'Ezugi',
  },
];

export const CASINO_WEB_NAV_PROVIDERS = [
  {
    publicUrl: Img21,
    title: 'Aviator',
  },
  // {
  //   publicUrl: Img1,
  //   title: 'Laser',
  // },
  {
    publicUrl: Img2,
    title: 'game burger',
    url: '/casino',
  },
  // {
  //   image: Img2,
  //   text: 'Evolution',
  //   url: '/casino/Evolution Gaming',
  // },
  // {
  //   publicUrl: Img3,
  //   title: 'Betsoft',
  //   url: '/casino',
  // },
  {
    publicUrl: Img4,
    title: 'AEsexy',
    url: '/casino',
  },
  // {
  //   publicUrl: Img5,
  //   title: 'blue print',
  // },
  {
    publicUrl: Img6,
    title: 'Super Spade Gaming',
  },
  // {
  //   publicUrl: Img7,
  //   title: 'Super Spade Gaming',
  // },
  // {
  //   image: Img8,
  //   text: 'Alchemy',
  //   url: '/casino',
  // },
  // {
  //   image: Img9,
  //   text: 'Casino',
  //   url: '/casino',
  // },
  // {
  //   image: Img10,
  //   text: 'blue guru',
  //   url: '/casino/JiLi Gaming',
  // },

  // {
  //   image: Img12,
  //   text: 'jili',
  //   url: '/casino/JiLi Gaming',
  // },
];
export const CASINO_WEB_NAV_PROVIDERS1 = [
  {
    image: Img2,
    text: 'game burger',
    url: '/casino',
  },

  {
    image: Img5,
    text: 'blue print',
    url: '/casino',
  },
  // {
  //   image: Img6,
  //   text: 'Super Spade Gaming',
  //   url: '/casino/Super Spade Gaming',
  // },
  {
    image: Img7,
    text: 'Super Spade Gaming',
    url: '/casino/Super Spade Gaming',
  },
  {
    image: Img8,
    text: 'Alchemy',
    url: '/casino',
  },
  {
    image: Img9,
    text: 'Casino',
    url: '/casino',
  },
  {
    image: Img10,
    text: 'blue guru',
    url: '/casino/JiLi Gaming',
  },

  // {
  //   image: Img12,
  //   text: 'jili',
  //   url: '/casino/JiLi Gaming',
  // },
];
export const CASINO_WEB_NAV_PROVIDERS2 = [

  {
    image: Img7,
    text: 'Super Spade Gaming',
    url: '/casino/Super Spade Gaming',
  },
  {
    image: Img8,
    text: 'Alchemy',
    url: '/casino',
  },
  {
    image: Img9,
    text: 'Casino',
    url: '/casino',
  },
  {
    image: Img10,
    text: 'blue guru',
    url: '/casino/JiLi Gaming',
  },

];

export const VIRTUAL_CASINO =
{
  // "urlThumb": Cards3,
  "901001": SevenUpDown,
  "901007": AmarAkbar,
  "901011": Bollywood,
  "901005": Cricket,
  "901003": Teenpatti,
  // "urlThumb": DusDum,
  // "urlThumb": DTigerLion,
  "901014": HiLo,
  // "urlThumb": DTigerOneDay,
  "901004": DTigerVirtual,
  // "urlThumb": FootBall,
  "901006": Baccarat,
  // "urlThumb": TwoCards,
  // "urlThumb": TwoCardsOneDay,
  "901010": Worli,
  // "urlThumb": TheTrap,
  // "urlThumb": Trio,
  "901012": Lottery,
  // "urlThumb": PokerOneDay,
  // "urlThumb": Poker,
  // "urlThumb": SuperOver,
  "901015": TeenpattiMuflis,
  // "urlThumb": CasinoHoldem,
  "901009": CasinoWar,
  // "urlThumb": TeenpattiOneDay,
}

export const CASINO_MOB_NAV_PROVIDERS = [
  {
    publicUrl: Img1,
    title: 'Laser',
  },
  {
    publicUrl: Img2,
    title: 'Laser1',
  },
  {
    publicUrl: Img3,
    title: 'Laser1',
  },
  {
    publicUrl: Img4,
    title: 'Super Spade Gaming',
  },
  {
    publicUrl: Img6,
    title: 'Super Spade Gaming',
  },
  {
    publicUrl: Img7,
    title: 'Super Spade Gaming',
  },
]

export const LIVE_WEB_CASINO = [
  {
    "id": 905000,
    "gameId": "900000",
    "category": "TABLE",
    "gameCode": "RG-LIVELOBBY",
    "gameName": "Royal Gaming Live Lobby",
    "urlThumb": F1,
    "urlBackground": "\"\"",
    "providerName": "RG",
    "subProviderName": "Royal Gaming",
    "status": "ACTIVE",
    "enable": "true"
  },
  {
    "id": 905001,
    "gameId": "900001",
    "category": "TABLE",
    "gameCode": "RG-7UD01",
    "gameName": "7 Up Down",
    "urlThumb": F2,
    "urlBackground": "\"\"",
    "providerName": "RG",
    "subProviderName": "Royal Gaming",
    "status": "ACTIVE",
    "enable": "true"
  },
  {
    "id": 905002,
    "gameId": "900002",
    "category": "TABLE",
    "gameCode": "RG-RT01",
    "gameName": "Auto Roulette",
    "urlThumb": F3,
    "urlBackground": "\"\"",
    "providerName": "RG",
    "subProviderName": "Royal Gaming",
    "status": "ACTIVE",
    "enable": "true"
  },
  {
    "id": 905004,
    "gameId": "900004",
    "category": "TABLE",
    "gameCode": "RG-TP01",
    "gameName": "Teen Patti",
    "urlThumb": F4,
    "urlBackground": "\"\"",
    "providerName": "RG",
    "subProviderName": "Royal Gaming",
    "status": "ACTIVE",
    "enable": "true"
  },
  {
    "id": 905005,
    "gameId": "900005",
    "category": "TABLE",
    "gameCode": "RG-DT01",
    "gameName": "Dragon Tiger",
    "urlThumb": F5,
    "urlBackground": "\"\"",
    "providerName": "RG",
    "subProviderName": "Royal Gaming",
    "status": "ACTIVE",
    "enable": "true"
  },
  {
    "id": 905006,
    "gameId": "900006",
    "category": "TABLE",
    "gameCode": "RG-CW01",
    "gameName": "Cricket War",
    "urlThumb": F6,
    "urlBackground": "\"\"",
    "providerName": "RG",
    "subProviderName": "Royal Gaming",
    "status": "ACTIVE",
    "enable": "true"
  },
  {
    "id": 905010,
    "gameId": "900010",
    "category": "TABLE",
    "gameCode": "RG-AAA01",
    "gameName": "Amar Akbar Anthony",
    "urlThumb": F7,
    "urlBackground": "\"\"",
    "providerName": "RG",
    "subProviderName": "Royal Gaming",
    "status": "ACTIVE",
    "enable": "true"
  },
  {
    "id": 905011,
    "gameId": "900011",
    "category": "TABLE",
    "gameCode": "RG-3CJ01",
    "gameName": "3 Cards Judgement",
    "urlThumb": F8,
    "urlBackground": "\"\"",
    "providerName": "RG",
    "subProviderName": "Royal Gaming",
    "status": "ACTIVE",
    "enable": "true"
  },
  {
    "id": 905012,
    "gameId": "900012",
    "category": "TABLE",
    "gameCode": "RG-CAW-01",
    "gameName": "Casino War",
    "urlThumb": F9,
    "urlBackground": "\"\"",
    "providerName": "RG",
    "subProviderName": "Royal Gaming",
    "status": "ACTIVE",
    "enable": "true"
  },
  {
    "id": 905013,
    "gameId": "900013",
    "category": "TABLE",
    "gameCode": "RG-WM01",
    "gameName": "Worli Matka",
    "urlThumb": F10,
    "urlBackground": "\"\"",
    "providerName": "RG",
    "subProviderName": "Royal Gaming",
    "status": "ACTIVE",
    "enable": "true"
  },
  {
    "id": 905014,
    "gameId": "900014",
    "category": "TABLE",
    "gameCode": "RG-BC01",
    "gameName": "Bollywood Casino",
    "urlThumb": F11,
    "urlBackground": "\"\"",
    "providerName": "RG",
    "subProviderName": "Royal Gaming",
    "status": "ACTIVE",
    "enable": "true"
  },
  {
    "id": 905015,
    "gameId": "900015",
    "category": "TABLE",
    "gameCode": "RG-LOT01",
    "gameName": "Lottery",
    "urlThumb": F12,
    "urlBackground": "\"\"",
    "providerName": "RG",
    "subProviderName": "Royal Gaming",
    "status": "ACTIVE",
    "enable": "true"
  },
  {
    "id": 905017,
    "gameId": "900017",
    "category": "TABLE",
    "gameCode": "RG-HL01",
    "gameName": "HighLow",
    "urlThumb": F13,
    "urlBackground": "\"\"",
    "providerName": "RG",
    "subProviderName": "Royal Gaming",
    "status": "ACTIVE",
    "enable": "true"
  },
  {
    "id": 905018,
    "gameId": "900018",
    "category": "TABLE",
    "gameCode": "RG-MTP01",
    "gameName": "Muflis Teen Patti",
    "urlThumb": F14,
    "urlBackground": "\"\"",
    "providerName": "RG",
    "subProviderName": "Royal Gaming",
    "status": "ACTIVE",
    "enable": "true"
  }
]




export const LIVE_WEB_CASINO1 = {
  "900000": F1,
  "900001": F2,
  "900002": F3,
  "900004": F5,
  "900005": F6,
  "900006": F6,
  "900010": F10,
  "900003": F15,
  "900011": F14,
  "900012": F11,
  "900013": F16,
  "900014": F12,
  "900015": F13,
  "900017": F12,
  "900018": F13,
  "901008": Cards3,
  "901001": SevenUpDown,
  "901007": AmarAkbar,
  "901011": Bollywood,
  "901005": Cricket,
  "901003": Teenpatti,
  "901006": Baccarat,
  "901013": DusDum,
  "901026": DTigerLion,
  "901014": HiLo,
  // "urlThumb": DTigerOneDay,
  "901004": DTigerVirtual,
  "901027": FootBall,
  // "urlThumb": Baccarat,
  "901019": TwoCards,
  // "urlThumb": TwoCardsOneDay,
  "901010": Worli,
  "901037": TheTrap,
  "901036": Trio,
  "901012": Lottery,
  // "urlThumb": PokerOneDay,
  "901030": Poker,
  "901034": SuperOver,
  "901015": TeenpattiMuflis,
  // "urlThumb": CasinoHoldem,
  "901009": CasinoWar,
  "901020": Cards32
}
