import React, { useState, useEffect } from 'react';
import {
  IonCardHeader,
  IonRow,
  IonCol,
  IonCardContent,
  IonLabel,
  IonInput,
  IonCard,
  IonLoading,
  IonTitle,
  IonSpinner,
  IonToast,
  IonButton,
  IonIcon,
} from '@ionic/react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import { bonusDetails } from '../../models/UserBet';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import './Referral.scss';

import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import { useFormik } from 'formik';
import ReportsSideBar from '../ReportsSideBar/ReportsSideBar';
import * as Yup from 'yup';
import TabPanel from '../TabPanel/TabPanel';
import { CloseOutlined } from '@material-ui/icons';
import { Drawer } from '@material-ui/core';
import { useHistory } from 'react-router';
import SVLS_API from '../../svls-api';
import { demoUserPrefix } from '../../util/stringUtil';
import { toast } from 'react-toastify';
import { BRAND_DOMAIN, BRAND_NAME } from '../../constants/Branding';
import { shareSocial } from 'ionicons/icons';
import { RootState } from '../../models/RootState';
import { connect } from 'react-redux';
import moment from 'moment';
import Deposit from '../../pages/Payment/Deposit';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SuccessIcon from '@material-ui/icons/CheckCircleOutline';

type ToastProps = {
  message: string;
  color: string;
  show: boolean;
};

type UpdateUserPersonalDetailsrequest = {
  fullName: string;
  phoneNumber: string;
  emailId: string;
  address: string;
  city: string;
  pinCode: string;
};

type UserPersonalDetails = UpdateUserPersonalDetailsrequest & {
  username: string;
};

type BonusAccount = {
  turnover: number;
  referred_by: string;
  referral_count: number;
  completed_referrals: number;
};

type StoreProps = {
  bonusEnabled: boolean;
};


const Referral: React.FC<StoreProps> = (props) => {
  const { bonusEnabled} = props;
  const search = window.location.search;
  const tab = new URLSearchParams(search).get('tab');
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<UserPersonalDetails>();
  const [toastDetails, setToastDetails] = useState<ToastProps>({
    message: '',
    color: '',
    show: false,
  });
  const [changePwdMOdal, setChangePwdMOdal] = useState<boolean>(false);
  const [phoneUpdate, setPhoneUpdate] = useState<boolean>(false);
  const [open2FAModal, setOpen2FAModal] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState<number>(tab ? 1 : 0);
  const [mfaEnabled, setMfaEnabled] = useState<boolean>(false);
  const history = useHistory();
  const [err, setErr] = useState<string>();
  const [referralCode, setReferralCode] = useState<UserPersonalDetails>();
  const [bonusAccount, setBonusAccount] = useState<BonusAccount>();
  const [bonusEarned, setBonusEarned] = useState<any>();
  const [bonusDetails, setBonusDetails] = useState<bonusDetails[]>([]);
  const [phone, setPhone] = useState<any>('');
  const [otpTimer, setOtpTimer] = useState<number>();
  const [otpEnterFields, setOtpEnterFields] = useState<boolean>(false);
  const [otpErrorMessage, setOtpErrorMessage] = useState<string>('');
  const [phoneNumbeErrorMsg, setPhoneNumbeErrorMsg] = useState<string>('');
  const [verificationErrorMsg, setVerificationErrorMsg] = useState<string>('');
  const [phoneNumberExists, setPhoneNumberExists] = useState<boolean>(false);
  const [country, setCountry] = useState<any>('');
  const [phoneFormat, setPhoneFormat] = useState<any>('');
  const [otp, setOtp] = useState<number>(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const userName = sessionStorage.getItem('username');
      const response = await SVLS_API.get(
        `/account/v2/users/${userName}/profile`,
        {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
        }
      );

      setUserDetails(response.data);
    } catch (err) {
      setErr(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchReferralCode = async () => {
    setLoading(true);
    try {
      const userName = sessionStorage.getItem('aid');
      const response = await SVLS_API.get(
        `/account/v2/accounts/${userName}/referral-code`,
        {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
        }
      );

      setReferralCode(response.data);
      // setPhone(response.data.phone);
    } catch (err) {
      setErr(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const validateOtp = async () => {
    setVerificationErrorMsg('');
    try {
      if (country.format.length === phoneFormat.length) {
        const response: any = await SVLS_API.post(
          `/account/v2/otp/validate?mobileNumber=${phone}&otp=${otp}`,
          {}
        );
        if (response.status === 204 || response.status === 200) {
          setOtpErrorMessage('');
          updatePhoneNumber();
        }
      }
    } catch (err) {
      setVerificationErrorMsg(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleNextStep = () => {
    if (phone === '') {
      toast.error('Please Enter Phone Number');
      return;
    }
    if (!otp || otp.toString().length !== 6) {
      setOtpErrorMessage('Please Enter Valid OTP');
      return;
    }
    setOtpErrorMessage('');
    setLoading(true);
    validateOtp();
  };

  const fetchBonusAccount = async () => {
    setLoading(true);
    try {
      const userName = sessionStorage.getItem('aid');
      const response = await SVLS_API.get(
        `marketing/v1/bonus-accounts/accounts/${userName}`,
        {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
        }
      );

      setBonusAccount(response.data);
      // setPhone(response.data.phone);
    } catch (err) {
      setErr(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleOtpTimer = (time) => {
    if (time >= 0) {
      setOtpTimer(time);
      setTimeout(() => {
        handleOtpTimer(time - 1);
      }, 1000);
    } else {
      setTimeout(() => {
        setOtpTimer(undefined);
      }, 1000);
    }
  };

  const generateOtp = async () => {
    setPhoneNumbeErrorMsg('');
    const claims = sessionStorage.getItem('jwt_token').split('.')[1];
    const username = JSON.parse(window.atob(claims)).sub;
    try {
      const response: any = await SVLS_API.post(
        `/account/v2/otp/?mobileNumber=${phone}&username=${username}&otpType=UPDATE_PHONE_NUMBER`
      );
      if (response.status === 204) {
        handleOtpTimer(60);
        setPhoneUpdate(false);
        setOtpEnterFields(true);
      }
    } catch (err) {
      setPhoneNumbeErrorMsg(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePhoneUpdate = (flag: boolean) => {
    if (flag && !phoneUpdate && otpEnterFields) {
      setOtpEnterFields(false);
    }
    if (!flag) {
      setPhone(userDetails?.phoneNumber);
    }
    setPhoneUpdate(flag);
  };
  
  const updatePhoneNumber = async () => {
    setVerificationErrorMsg('');
    try {
      const claims = sessionStorage.getItem('jwt_token').split('.')[1];
      const username = JSON.parse(window.atob(claims)).sub;
      if (country.format.length === phoneFormat.length) {
        const response: any = await SVLS_API.put(
          `/account/v2/users/${username}/phoneNumber`,
          {
            phone_number: phone,
            otp: otp,
          },
          {
            headers: {
              Authorization: sessionStorage.getItem('jwt_token'),
            },
          }
        );
        if (response.status === 204 || response.status === 200) {
          setOtpErrorMessage('');
          toast.success(
            <div>
              <SuccessIcon /> {'Phone Number updated successfully.'}
            </div>,
            {
              position: 'top-center',
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          setPhoneNumberExists(false);
          setOtpEnterFields(false);
          setPhoneUpdate(false);
        }
      }
    } catch (err) {
      setVerificationErrorMsg(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchBonusDetails = async () => {
    setLoading(true);
    try {
      const response = await SVLS_API.get('/marketing/v1/bonuses/user/referral-details', {
        headers: {
          Authorization: sessionStorage.getItem('jwt_token'),
        },
      });
      if (response.status === 200) {
        setBonusDetails(response?.data?.referred_users);
        setBonusEarned(response?.data?.total_bonus_earned);
      }
    } catch (err) {
      setErr(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log(bonusDetails, 'bonus');
  }, [bonusDetails]);

  useEffect(() => {
    fetchData();
    fetchBonusDetails();
  }, []);

  useEffect(() => {
    if (bonusEnabled) {
      fetchReferralCode();
      fetchBonusAccount();
    }
  }, [bonusEnabled]);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const pinCodeRegex = /^[1-9][0-9]{5}$/;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fullName: userDetails ? userDetails.fullName : '',
      phoneNumber: userDetails ? userDetails.phoneNumber : '',
      emailId: userDetails ? userDetails.emailId : '',
      address: userDetails ? userDetails.address : '',
      city: userDetails ? userDetails.city : '',
      pinCode: userDetails ? userDetails.pinCode : '',
    },
    validationSchema: Yup.object({
      phoneNumber: Yup.string().matches(
        phoneRegExp,
        'Phone number is not valid'
      ),
      pinCode: Yup.string().matches(pinCodeRegex, 'Pin Code is not valid'),
    }),
    onSubmit: (values) => {
      let data: UpdateUserPersonalDetailsrequest = {
        fullName: values.fullName,
        phoneNumber: values.phoneNumber,
        emailId: values.emailId,
        address: values.address,
        city: values.city,
        pinCode: values.pinCode,
      };
      updateDetails(data);
    },
  });

  const updateDetails = async (data: UpdateUserPersonalDetailsrequest) => {
    try {
      setErr('');
      setProgress(true);
      const userName = sessionStorage.getItem('username');
      const response = await SVLS_API.put(
        `/account/v2/users/${userName}/profile`,
        data,
        {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
        }
      );
      if (response.status === 204) {
        setToastDetails({
          message: 'Details saved successfully',
          color: 'success',
          show: true,
        });
      } else {
        setToastDetails({
          message: 'Unable to save details, something went wrong!',
          color: 'danger',
          show: true,
        });
      }
      fetchData();
    } catch (err) {
      setErr(err?.response?.data?.message);
      setToastDetails({
        message: 'Unable to save details, something went wrong!',
        color: 'danger',
        show: true,
      });
    } finally {
      setProgress(false);
    }
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const copyText = () => {
    let text = `${referralCode}`;
    navigator.clipboard.writeText(text);
    toast.success('Referral code copied!');
  };

  const generateRefLink = () => {
    let link = `${BRAND_DOMAIN}/home?refCode=${referralCode}&tab=1`;
    navigator.clipboard.writeText(link);
    toast.success('Referral link copied!');
  };

  const handleOnBack = () => {
    history.goBack();
  };

  return (
    <>
    <IonRow>
    <IonCol sizeMd="1.7" sizeLg="1.7" className="web-view">
        <div className="compt-menu-section">
          <div className="sticky-col">
            <ReportsSideBar />
          </div>
        </div>
      </IonCol>
      <IonCol>
      <form
          onSubmit={formik.handleSubmit}
          className="change-pwd-form-ctn"
          autoComplete="off"
        >

    <div>
    <div className="change-password-title">
            <span onClick={handleOnBack} className="back-arrow">
              <ArrowBackIcon />
            </span>
           Referral
          </div>
       
     {bonusEnabled ? (
                  
                      <div className="contacts-tab-ctn">
                        <div className="referral-card-bg">
                          <div className="referral-heading">
                            Earn bonus by referring friends.
                          </div>
                          <div className="referral-content">
                            Refer someone to {BRAND_NAME} and both you and your
                            friend will earn. The bonus will be unlocked once
                            your friend place a bet or complete conditions of
                            platform.
                          </div>
                        </div>

                        <div className="summary-cards-row">
                          <div className="summary-cards">
                            <div className="summary-cards-header">
                              Total Bonus Earned
                            </div>
                            <div className="summary-cards-content">
                              {bonusEarned}
                            </div>
                          </div>

                          <div className="summary-cards">
                            <div className="summary-cards-header">
                              Completed Referrals
                            </div>
                            <div className="summary-cards-content">
                              {' '}
                              {bonusAccount?.completed_referrals ?? '0'}{' '}
                            </div>
                          </div>
                          <div className="summary-cards">
                            <div className="summary-cards-header">
                              {' '}
                              Accepted Referrals
                            </div>
                            <div className="summary-cards-content">
                              {bonusAccount?.referral_count ?? '0'}
                            </div>
                          </div>
                        </div>

                        <IonRow className="input-row referral-sub-section">
                          <div className="input-label sub-header">
                          Referral Code{' '}
                          </div>
                          <div className="update-row">
                            {/* <span>Refer a friend and avail referral bonus</span> */}
                            <span
                              title="Click to Code"
                              className="referral-code-field"
                              onClick={() => copyText()}
                            >
                              {referralCode}
                            </span>
                            <Button
                              className="referral-code-copy-btn"
                              onClick={() => generateRefLink()}
                            >
                              Copy Link
                            </Button>
                            {/* <IonIcon
                  title="Share your referral link"
                  size="xl"
                  icon={shareSocial}
                  color="primary"
                  style={{ cursor: 'pointer' }}
                  className="share-icon"
                  onClick={() => generateRefLink()}
                ></IonIcon> */}
                            {/* <TextField
                  disabled={true}
                  placeholder="Referral Code"
                  className="acc-input"
                  value={referralCode} */}
                            {/* // InputProps={{ */}
                            {/* //   endAdornment: (
                  //     // <InputAdornment position="end">
                  //     <IonSpinner name="lines-small" />
                  //     // </InputAdornment>
                  //   ),
                  // }} */}
                            {/* // onChange={(e) => setDepositNotes(e.target.value)} */}
                            {/* // /> */}
                          </div>
                        </IonRow>
                        <div className="referral-table">
                          <div className="referral-label ">
                            Track your referral:
                          </div>
                          <div className='reports-ctn my-wallet-ctn'>
                          <TableContainer component={Paper}>
                          
                            <Table
                              className="referral-table" size="small">
                            
                              <TableHead className="tbl-header-section">
                                <TableRow>
                                  <TableCell align="center">Date</TableCell>
                                  <TableCell align="center">
                                    Friend User Name
                                  </TableCell>
                                  <TableCell align="center">
                                    Reward Earned
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              {bonusDetails.length > 0 ? (
                                <>
                               
                                  <TableBody >
                                    {bonusDetails?.map((row) => (
                                      <TableRow className='tb-row'>
                                        <TableCell
                                      component="th"
                                      scope="row"
                                      className="tb-col date-col "
                                      align="left"
                                    >
                                          {moment(row.referred_date).format(
                                            'DD-MM-YY, h:mm:ss A'
                                          )}
                                        </TableCell>
                                        <TableCell align="center">
                                          {' '}
                                          {row?.username}
                                        </TableCell>
                                        <TableCell align="center">
                                          {' '}
                                          {row?.reward_earned}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                
                                </>
                              ) : (
                                <TableCell
                                  className="no-data-row bordernone"
                                  colSpan={9}
                                >
                                  <div className="no-bets-msg">
                                    No Details Found
                                  </div>
                                </TableCell>
                              )}
                            </Table>
                          </TableContainer>
                          </div>
                        </div>
                        {/* <div className="referral-sub-section">
              <div className="sub-header">Your referral status</div>
              <div>
                <span>
                  Completed Referrals :{' '}
                  {bonusAccount?.completed_referrals ?? '0'}{' '}
                </span>
                <span>
                  Accepted Referrals : {bonusAccount?.referral_count ?? '0'}
                </span>
              </div>
            </div> */}
                      </div>
                   
                  ) : null}
    </div>
    </form>
    </IonCol>
    </IonRow>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    bonusEnabled: state.common.domainConfig.bonus,
  };
};

export default connect(mapStateToProps)(Referral);
