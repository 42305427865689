import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../models/RootState';
import { IonRow, IonCol } from '@ionic/react';
import {
  TextField,
  FormHelperText,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Button,
  IconButton,
  makeStyles,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { IonSpinner } from '@ionic/react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import './SignUp.scss';
import logo from "../../assets/images/brand/title.png";
import { CheckBox } from '@material-ui/icons';
import HorizontalLabelPositionBelowStepper from './Steps';
import PhoneInput from 'react-phone-input-2';
import { BRAND_NAME } from '../../constants/Branding';
import SVLS_API from '../../svls-api';
import API from '../../api';

type StoreProps = {
  setTabValue: Function;
  modalCloseHandler: Function;
};

const useStyles = makeStyles({
  customTextField: {
    "& input::placeholder": {
      fontSize: "16px",
      fontFamily: 'Montserrat',
      fontWeight: "400",
      lineHeight: "16px",
      color: "#fff",
      opacity: "0.5"
    }
  }
})

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SignUp: React.FC<StoreProps> = (props) => {
  const refCode = useQuery().get('refCode');
  const { setTabValue, modalCloseHandler } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [userErrorMsg, setUserErrorMsg] = useState<string>('');
  const [otp, setOtp] = useState<number>(null);
  const [referralCode, setReferralCode] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [agreeTerms, setAgreeTerms] = useState<boolean>(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [phone, setPhone] = useState<any>('');
  const [country, setCountry] = useState<any>('');
  const [phoneFormat, setPhoneFormat] = useState<any>('');
  const [otpTimer, setOtpTimer] = useState<number>();
  const [otpEnterFields, setOtpEnterFields] = useState<boolean>(false);
  const [otpErrorMessage, setOtpErrorMessage] = useState<string>('');
  const [phoneNumbeErrorMsg, setPhoneNumbeErrorMsg] = useState<string>('');
  const [verificationErrorMsg, setVerificationErrorMsg] = useState<string>('');
  const [signUpErrorMsg, setsignUpErrorMsg] = useState<string>('');
  const [phoneNumberExists, setPhoneNumberExists] = useState<boolean>(false);

  let history = useHistory();
  const formik = useFormik({
    initialValues: {
      fullname: '',
      username: '',
      password: '',
      // phoneNumber: '',
      address: '',
      otp: null,
      referralCode: refCode ? refCode : ''
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .required('Required')
        .min(4, 'The User Name field must be at least 4 characters'),
      fullname: Yup.string().required('Required'),
      password: Yup.string().required('Required'),
      referralCode: Yup.string()
    }),
    onSubmit: async (values) => {
      setLoading(true);
      let postBody = {
        fullName: values.fullname.trim(),
        username: values.username.toLowerCase(),
        password: values.password,
        phoneNumber: phone,
        otp: otp,
        referralCode: values.referralCode
      };
      setsignUpErrorMsg('');
      try {
        const response: any = await SVLS_API.post(
          '/account/v2/accounts/signup',
          postBody,
          {}
        );

        toast.success("Signed up successfully");
        modalCloseHandler();
        history.push('/login');
      } catch (err) {
        setsignUpErrorMsg(err?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    },
  });

  const showPasswordClickHandler = () => {
    setShowPassword(!showPassword);
  };

  const handleOtpTimer = (time) => {
    if (time >= 0) {
      setOtpTimer(time);
      setTimeout(() => {
        handleOtpTimer(time - 1);
      }, 1000);
    } else {
      setTimeout(() => {
        setOtpTimer(undefined);
      }, 1000);
    }
  };

  const handleChange = (e) => {
    setOtpErrorMessage('');
    setVerificationErrorMsg('');
    const { target } = e;
    const { value, name } = target;
    if (name === 'phone') {
      setPhone(value);
    } else if (name === 'otp') {
      setOtp(value);
    } else if (name === 'referralCode') {
      setReferralCode(value);
    }
  };

  const sendOTP = async () => {
    if (!phone) {
      setPhoneNumbeErrorMsg('Please enter a valid phone number');
    }
    try {
      if (country.format?.length === phoneFormat?.length) {
        const response: any = await SVLS_API.post(`/account/v2/otp/`, null, {
          params: { mobileNumber: phone.toString() },
        });
        console.log(response.data);
        if (response.status === 204) {
          handleOtpTimer(60);
          setOtpEnterFields(true);
        } else {
          setPhoneNumbeErrorMsg('Something went wrong, Please try again');
        }
      }
    } catch (err) {
      setPhoneNumbeErrorMsg('Something went wrong, Please try again');
      // toast.error(err.toString());
      setLoading(false);
    }
  };

  useEffect(() => {
    if (phone && phone?.length - country?.dialCode.length + 1 > 10) {
      checkPhoneNumberExists(phone);
    } else {
      setPhoneNumbeErrorMsg('');
    }
  }, [phone]);

  const checkPhoneNumberExists = async (phoneNumber: string) => {
    setLoading(true);
    try {
      const response: any = await SVLS_API.get(
        `/account/v2/users/phones/${phoneNumber}/:exists`
      );
      if (response.status === 200) {
        if (response.data === true) {
          setPhoneNumbeErrorMsg('Phone number already exists.');
          setPhoneNumberExists(true);
        } else {
          setPhoneNumbeErrorMsg('');
          setPhoneNumberExists(false);
        }
      }
    } catch (err) {
      setPhoneNumbeErrorMsg(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const validateOtp = async () => {
    try {
      if (country.format.length === phoneFormat.length) {
        const response: any = await SVLS_API.post(
          `account/v2/otp/validate`,
          null,
          {
            params: {
              mobileNumber: phone,
              otp: otp,
            },
          }
        );
        if (response && response.status === 204) {
          setLoading(false);
          setActiveStep(1);
          setOtpErrorMessage('');
        }
      }
    } catch (err) {
      setVerificationErrorMsg('Something went wrong, please try again.');
      setLoading(false);
    }
  };

  const handleChangeSignUpError = () => {
    setsignUpErrorMsg('');
  }

  const handleNextStep = () => {
    if (phone === '') {
      toast.error('Please Enter Phone Number');
      return;
    }
    if (!otp || otp.toString().length !== 6) {
      setOtpErrorMessage('Please Enter Valid OTP');
      return;
    }
    setOtpErrorMessage('');
    setLoading(true);
    validateOtp();
  };

  const checkUserName = async (e) => {
    setsignUpErrorMsg('');
    try {
      const { target } = e;
      const { value } = target;
      if (value.length > 3) {
        const response: any = await API.post(`/user/${value}:exists`);
        if (response.status === 200) {
          if (response.data) {
            setUserErrorMsg('User name already exists');
          } else {
            setUserErrorMsg('');
          }
        }
      }
    } catch (err) {
      // setsignUpErrorMsg('Something went wrong, Please try again');
    }
  };

  const classes = useStyles();

  return (
    <div className="login-form-ctn">
      <IonRow>
        <IonCol sizeLg="12" sizeXs="12" className="card-col">
          <img height={50} src={logo} />
          <div className="sign-in-title">
            Sign Up
          </div>
          {activeStep === 0 ? (
            <>
              <IonRow className="input-row">
                {' '}
                <HorizontalLabelPositionBelowStepper
                  activeStep={activeStep}
                />{' '}
              </IonRow>
              <IonRow>
                <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" className="login-col">
                  <PhoneInput
                    country={'in'}
                    placeholder="Enter Phone Number"
                    value={phone}
                    countryCodeEditable={false}
                    onChange={(value, country, e, formattedValue) => {
                      setPhone(value);
                      setCountry(country);
                      setPhoneFormat(formattedValue);
                    }}
                    disabled={otpEnterFields}
                  />
                  {phoneNumbeErrorMsg ? (
                    <FormHelperText error id="my-helper-text">
                      {phoneNumbeErrorMsg}
                    </FormHelperText>
                  ) : null}
                </IonCol>
              </IonRow>
              {!otpEnterFields ? (
                <>
                  <IonRow>
                    <FormGroup className="terms-conditions">
                      <FormControlLabel className="terms-text"
                        control={
                          <Checkbox
                            onChange={() => setAgreeTerms(!agreeTerms)}
                          />
                        }
                        label={`
                            I agree to the all Terms & Conditions of ${BRAND_NAME}.`}
                      />
                    </FormGroup>
                  </IonRow>

                  <div className="login-col">
                    <Button
                      className="login-form-btn"
                      color="primary"
                      type="submit"
                      variant="contained"
                      endIcon={
                        loading ? <IonSpinner name="lines-small" /> : ''
                      }
                      disabled={!agreeTerms || (phone === '') || (phone?.length - country?.dialCode.length + 1) <= 10 || phoneNumberExists}
                      onClick={() => sendOTP()}
                    >
                      Send OTP
                    </Button>
                  </div>
                </>
              ) : null}

              {otpEnterFields ? (
                <>
                  <IonRow>
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" className="login-col">
                      <div className="input-row flex-column">
                        {/* <div className="label">OTP *</div> */}
                        <TextField
                          className="number-input"
                          type="number"
                          name="otp"
                          variant="outlined"
                          placeholder="Enter OTP"
                          error={
                            otpErrorMessage !== ''
                              ? true
                              : false ||
                                verificationErrorMsg !== ''
                                ? true
                                : false
                          }
                          helperText={
                            otpErrorMessage !== ''
                              ? otpErrorMessage
                              : null ||
                                verificationErrorMsg !== ''
                                ? verificationErrorMsg
                                : null
                          }
                          onKeyPress={(evt) => {
                            if (
                              (evt.which != 8 &&
                                evt.which != 0 &&
                                evt.which < 48) ||
                              evt.which > 57
                            ) {
                              evt.preventDefault();
                            }
                          }}
                          onChange={(e) => handleChange(e)}
                        />
                        <div
                          className="label link"
                          onClick={() => {
                            if (!otpTimer) {
                              sendOTP();
                            }
                          }}
                        >
                          {otpTimer !== undefined &&
                            otpTimer !== null &&
                            otpTimer >= 0
                            ? `Resend in ${otpTimer} sec`
                            : 'Send OTP'}
                        </div>
                      </div>
                    </IonCol>
                  </IonRow>
                  <div className="login-col">
                    <Button
                      className="login-form-btn"
                      color="primary"
                      type="submit"
                      variant="contained"
                      endIcon={
                        loading ? <IonSpinner name="lines-small" /> : ''
                      }
                      disabled={!agreeTerms}
                      onClick={() => handleNextStep()}
                    >
                      Verify OTP
                    </Button>
                  </div>
                </>
              ) : null}

            </>
          ) : null}
          {activeStep === 1 ? (
            <>
              <form
                onSubmit={formik.handleSubmit}
                className="card"
                autoComplete="off"
              >
                <IonRow>
                  {' '}
                  <HorizontalLabelPositionBelowStepper
                    activeStep={activeStep}
                  />{' '}
                </IonRow>

                <IonCol
                  sizeXs="12"
                  sizeSm="12"
                  sizeMd="12"
                  sizeLg="12"
                  className="login-col"
                >
                  <IonRow className="input-row">
                    <div className="form-control">
                      {/* <div className="label">Username *</div> */}
                      <TextField
                        className="login-input-field user-name"
                        type="text"
                        placeholder="Enter Username"
                        variant="outlined"
                        name="username"
                        onKeyUp={(e) => checkUserName(e)}
                        error={
                          (formik.touched.username &&
                            formik.errors.username) ||
                            userErrorMsg !== ''
                            ? true
                            : false
                        }
                        helperText={
                          formik.touched.username && formik.errors.username
                            ? formik.errors.username
                            : userErrorMsg !== ''
                              ? userErrorMsg
                              : null
                        }
                        {...formik.getFieldProps('username')}
                      />
                    </div>
                  </IonRow>
                </IonCol>

                <IonCol
                  sizeXs="12"
                  sizeSm="12"
                  sizeMd="12"
                  sizeLg="12"
                  className="login-col"
                >
                  <IonRow className="input-row">
                    <div className="form-control">
                      <TextField
                        className="login-input-field user-name"
                        type="text"
                        name="fullname"
                        variant="outlined"
                        placeholder="Enter Fullname"
                        onKeyUp={() => handleChangeSignUpError()}
                        error={
                          formik.touched.fullname && formik.errors.fullname
                            ? true
                            : false
                        }
                        helperText={
                          formik.touched.fullname && formik.errors.fullname
                            ? formik.errors.fullname
                            : null
                        }
                        {...formik.getFieldProps('fullname')}
                      />
                    </div>
                  </IonRow>
                </IonCol>

                <IonCol
                  sizeXs="12"
                  sizeSm="12"
                  sizeMd="12"
                  sizeLg="12"
                  className="login-col"
                >
                  <IonRow className="input-row">
                    <div className="form-control">
                      <FormControl
                        className="login-input-field pwd-field"
                        variant="outlined"
                        onChange={() => handleChangeSignUpError()}
                        error={
                          formik.touched.password && formik.errors.password
                            ? true
                            : false
                        }
                      >
                        <OutlinedInput
                          id="standard-adornment-password"
                          type={showPassword ? 'text' : 'password'}
                          name="password"
                          placeholder="Enter Password"
                          {...formik.getFieldProps('password')}
                          endAdornment={
                            <InputAdornment position="end" className="eye-button">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={showPasswordClickHandler}
                                onMouseDown={showPasswordClickHandler}
                              >
                                {showPassword ? (
                                  <Visibility className="no-margin" />
                                ) : (
                                  <VisibilityOff className="no-margin" />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        {formik.touched.password && formik.errors.password ? (
                          <FormHelperText error id="my-helper-text">
                            {formik.errors.password}
                          </FormHelperText>
                        ) : null}
                        {signUpErrorMsg ? (
                          <FormHelperText error id="my-helper-text">
                            {signUpErrorMsg}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </div>

                  </IonRow>
                </IonCol>

                <IonCol
                  sizeXs="12"
                  sizeSm="12"
                  sizeMd="12"
                  sizeLg="12"
                  className="login-col"
                >
                  <IonRow className="input-row">
                    <div className="form-control">
                      {/* <div className="label">Username *</div> */}
                      <TextField
                        className="login-input-field user-name"
                        type="text"
                        placeholder="Enter Referral Code (Optional)"
                        variant="outlined"
                        name="referralCode"
                        disabled={refCode ? true : false}
                        error={
                          (formik.touched.referralCode &&
                            formik.errors.referralCode) ||
                            userErrorMsg !== ''
                            ? true
                            : false
                        }
                        helperText={
                          formik.touched.referralCode && formik.errors.referralCode
                            ? formik.errors.referralCode
                            : userErrorMsg !== ''
                              ? userErrorMsg
                              : null
                        }
                        {...formik.getFieldProps('referralCode')}
                      />
                    </div>
                  </IonRow>
                </IonCol>

                <IonRow></IonRow>
                <IonRow className="login-col">
                  <Button
                    className="login-form-btn"
                    color="primary"
                    endIcon={
                      loading ? <IonSpinner name="lines-small" /> : ''
                    }
                    type="submit"
                    variant="contained"
                    disabled={!(formik.isValid && userErrorMsg === '')}
                  >
                    SignUp
                  </Button>
                </IonRow>
              </form>
            </>
          ) : null}
        </IonCol>
      </IonRow>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    loggedIn: state.auth.loggedIn,
  };
};

export default connect(mapStateToProps)(SignUp);