import React from 'react';
import { IonSpinner } from '@ionic/react';
import './Spinner.scss';
import logo from "../../assets/images/brand/title.png";

const Spinner: React.FC = () => {
  return (
    <div className="spin">
      {/* <IonSpinner name="lines" /> */}
      <div className="web-view">
        <img src={logo} height={40} alt="web_spinner" />
      </div>
      <div className="mob-view">
        <img src={logo} height={30} alt="mob_spinner" />
      </div>
    </div>
  );
};

export default Spinner;
