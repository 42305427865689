import React, { useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../models/RootState';
import { GapGame } from '../../models/dc/DcGame';
import { Backdrop } from '@material-ui/core';
import './CardView.scss';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import LoginModal from '../Modal/LoginModal';
import LoginForm from '../LoginForm/LoginForm';
import { IonCol, IonRow } from '@ionic/react';
import { LIVE_WEB_CASINO1 } from '../../constants/CasinoView';

type StoreProps = {
  loggedIn: boolean;
  liveCasinoGames: GapGame[];
  heading?: string;
  loggedInUserStatus: any;
};
const CardView: React.FC<StoreProps> = (props) => {
  const { loggedIn, liveCasinoGames, heading, loggedInUserStatus } = props;
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const history = useHistory();

  let slider;
  const next = () => {
    slider.slickNext();
  };
  const previous = () => {
    slider.slickPrev();
  };

  let location = useLocation()

  const getGameUrl = async (
    gameId: string,
    gameName: string,
    gameCode: string,
    provider: string,
    subProvider: string
  ) => {
    if (loggedInUserStatus === 0 || loggedInUserStatus === 3) {
      history.push(`/home`);
    }
    if (provider === 'Indian Casino') {
      history.push(`/casino/indian/${gameCode}`);
    } else {
      history.push(
        `/gap_casino/game/${gameName.toLowerCase().replace(/\s+/g, '-')}-${btoa(
          gameId.toString()
        )}-${btoa(gameCode)}-${btoa(provider)}-${btoa(subProvider)}`
      );
    }
  };

  const setDialogShow = (show: boolean) => {
    setShowDialog(show);
  };

  const handleGameClick = async (
    gameId: string,
    gameName: string,
    gameCode: string,
    subProvider: string,
    provider?: string
  ) => {
    if (loggedIn) {
      getGameUrl(gameId, gameName, gameCode, provider, subProvider);
    } else {
      setDialogShow(true);
    }
  };

  const settings = {
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
    dots: false,
    infinite: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    arrows: false,
    pauseOnHover: true,
    autoplay: true,
    autoplaySpeed: 1000,
  };

  return (
    <>
      <div className="games-ctn">
        <div className="heading">
          <div className="title">{heading}</div>
          {/* <div className="slider-btn">
          <Button className="btn btn-load-more" onClick={next}>
            Load More
          </Button>
          <Button className="btn1" onClick={next}>
            Next
          </Button>
        </div> */}
        </div>
        <div className="title-border-line"></div>
        {/* <Slider {...settings} ref={(c) => (slider = c)}> */}
        <IonRow className="dc-games-ctn">
          {liveCasinoGames?.length > 0 ?
            liveCasinoGames.map((g, i) => {
              return (
                <>
                  {g?.urlThumb ? (
                    <IonCol
                      sizeXs="4"
                      sizeSm="4"
                      sizeMd="2"
                      sizeLg="2"
                      key={g.gameId}
                      className="dc-ion-col"
                      style={{ display: "flex" }}
                    >
                      <div className="img-container pointer w-100"
                        onClick={() =>
                          handleGameClick(
                            g.gameId,
                            g.gameName,
                            g.gameCode,
                            g.subProviderName,
                            g.providerName
                          )
                        }>
                        <img
                          src={LIVE_WEB_CASINO1[g.gameId]? LIVE_WEB_CASINO1[g.gameId]: g.urlThumb}
                          alt={g.gameName}
                          className="dc-img"
                        />
                        <div className="game-name">
                          <span className="name web-view">{g.gameName.length > 20 ? g.gameName.slice(0, 20) + '..' : g.gameName}</span>
                          <span className="name mob-view">{g.gameName.length > 16 ? g.gameName.slice(0, 16) + '..' : g.gameName}</span>
                          {/* <span className="p-name">{g.subProviderName}</span> */}
                          <div className='tooltip'>
                            <span className="tooltiptext">{g.subProviderName}</span>
                            {/* <img title={g.subProviderName} className='info-img' src={Info} /> */}
                          </div>
                        </div>
                        {/* <div className="overlay1">
                        <div className="text1">
                          <div
                            className="custom-btn"
                            // onClick={() =>
                            //   handleGameClick(
                            //     g.gameId,
                            //     g.gameName,
                            //     g.gameCode,
                            //     g.subProviderName,
                            //     g.providerName
                            //   )
                            // }
                          >
                            <img src={inplay} alt="inplay" />
                            <div> PLAY NOW </div>
                          </div>
                        </div>
                      </div> */}
                      </div>
                    </IonCol>
                  ) : null}
                </>
              );
            })
            : <div>No {location?.pathname?.split('/home/')[1]} games available</div>
          }
        </IonRow>
        {/* </Slider> */}
      </div>
      <Backdrop className="backdrop-ctn" open={showDialog}>
        <LoginModal
          open={showDialog}
          closeHandler={() => setDialogShow(false)}
          noTitle={true}
          size="sm"
          customClass={'login-modal-ctn'}
        >
          <LoginForm modalCloseHandler={() => setDialogShow(false)}></LoginForm>
        </LoginModal>
      </Backdrop>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  let status = 0;
  if (state.auth.loggedIn) {
    status = JSON.parse(
      window.atob(sessionStorage.getItem('jwt_token').split('.')[1])
    ).status;
  }
  return {
    loggedIn: state.auth.loggedIn,
    loggedInUserStatus: status,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(CardView);
