import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { RootState } from '../../../models/RootState';
import './CasinoIframeNew.scss';
import API from '../../../api';
import { isMobile, isIOS } from 'react-device-detect';
import LoadingPage from '../../../pages/LoadingPage/LoadingPage';
import SVLS_API from '../../../svls-api';

type StoreProps = {
  gameUrl: string;
  loggedIn: boolean;
};

type RouteParams = {
  gamePath: string;
};

const CasinoIframeNew: React.FC<StoreProps> = (props) => {
  const [gameSrc, setGameSrc] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const { loggedIn } = props;

  const { gamePath } = useParams<RouteParams>();
  const history = useHistory();

  const getGameUrl = async (
    gameId: string,
    gameCode: string,
    provider: string,
    subProvider: string
  ) => {
    if (loggedIn) {
      setLoading(true);
      const claims = sessionStorage.getItem('jwt_token').split('.')[1];
      const userStatus = JSON.parse(window.atob(claims)).status;

      if (userStatus === 0 || userStatus === 3) {
        return history.push(`/home`);
      }

      // if (provider.toLocaleLowerCase() === 'ezugi') {
      let response;
      try {
        const reqBody = {
          gameId: gameId,
          providerName: provider,
          subProviderName: subProvider,
          platformId: isMobile ? 'mobile' : 'desktop',
        };
        response = await SVLS_API.post('/catalog/v2/live-casino/game-url', reqBody, {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
        });
      } catch (e) {
        console.log(e);
      }

      if (response) {
        if (isMobile && isIOS) {
          setLoading(false);
          // window.location.href = response.data.url;
          setGameSrc(response.data);
        } else {
          setGameSrc(response.data);
          setLoading(false);
        }
      }
    } else {
      history.push(`/`);
    }
  };

  useEffect(() => {
    const gameId = atob(gamePath.split('-')[gamePath.split('-').length - 4]);
    const gameCode = atob(gamePath.split('-')[gamePath.split('-').length - 3]);
    const provider = atob(gamePath.split('-')[gamePath.split('-').length - 2]);
    const subProvider = atob(
      gamePath.split('-')[gamePath.split('-').length - 1]
    );

    getGameUrl(gameId, gameCode, provider, subProvider);
  }, []);

  return (
    <div className="dc-iframe-ctn">
      {loading ? (
        <LoadingPage />
      ) : (
        <iframe
          src={gameSrc}
          title="DC game"
          allowFullScreen
          sandbox="allow-same-origin allow-forms allow-scripts allow-top-navigation allow-popups"
        ></iframe>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    gameUrl: state.common.dcGameUrl,
    loggedIn: state.auth.loggedIn,
  };
};

export default connect(mapStateToProps)(CasinoIframeNew);
