import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useParams } from 'react-router-dom';

import { RootState } from '../../models/RootState';
import './SportsProviderIframe.scss';
import LoadingPage from '../../pages/LoadingPage/LoadingPage';
import { BRAND_DOMAIN } from '../../constants/Branding';
import API from '../../api';
import SVLS_API from '../../svls-api';

type StoreProps = {
  gameUrl: string;
  loggedIn: boolean;
  prefersDark?: string;
};

type RouteParams = {
  gamePath: string;
};

const SportsProviderIframe: React.FC<StoreProps> = (props) => {
  const [gameSrc, setGameSrc] = useState<string>('');
  const [url, setUrl] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const { loggedIn } = props;
  const pathname = useLocation().pathname;
  const { gamePath } = useParams<RouteParams>();
  const history = useHistory();

  const getGameUrl = async () => {
    if (loggedIn) {
      setLoading(true);
      const claims = sessionStorage?.getItem('jwt_token')?.split('.')[1];
      const userStatus = claims ? JSON.parse(window.atob(claims))?.status : 0;

      if (userStatus === 0 || userStatus === 3) {
        return history.push(`/home`);
      }
      let response;
      try {
        const reqBody = 'desktop';
        response = await SVLS_API.get(
          `/catalog/v2/sports-book/lobby-urls/${reqBody}`,
          {
            headers: {
              Authorization: sessionStorage.getItem('jwt_token'),
            },
            params: {
              isExchange: pathname?.includes("/exchange")? true: false
            }
          },
        );
      } catch (e) {
        console.log(e);
      }
      if (response) {
        if (response.data.url) {
          const callBackUrl = `&callBackUrl=https://dev.hypexexch.com/exchange_sports/inplay`;
          // if (prefersDark === 'yellow') {
          setGameSrc(
            response.data.url +
              callBackUrl
          );
          // } else {
          //   setGameSrc(
          //     response.data.url +
          //       +callBackUrl +
          //       '&primary=ED1B24&secondary=fff&background=fff&background1=231f20&textColor=000'
          //   );
          // }
        }
        setLoading(false);
      }
      setLoading(false);
    } else {
      history.push(`/`);
    }
  };

  useEffect(() => {
    getGameUrl();
  }, []);

  return (
    <div className="ps-iframe-ctn">
      {loading ? (
        <LoadingPage />
      ) : (
        <iframe
          src={gameSrc}
          title="Sports Book"
          allowFullScreen
          sandbox="allow-same-origin allow-forms allow-scripts allow-top-navigation allow-popups"
        ></iframe>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    gameUrl: state.common.dcGameUrl,
    loggedIn: state.auth.loggedIn,
  };
};

export default connect(mapStateToProps)(SportsProviderIframe);
