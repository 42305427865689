import React, { useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../models/RootState';
import MenuIcon from '@material-ui/icons/Menu';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import SideNav from './SideNav';
import './MobileRightSideNav.scss';
import dashboardIcon from '../../assets/images/common/dashboard.svg';
import { IonImg } from '@ionic/react';

type StoreProps = {
  loggedIn: boolean;
};

const MobileRightSideNav: React.FC<StoreProps> = (props) => {
  const [state, setNewState] = useState<{
    isPaneOpen: boolean;
    isPaneOpenLeft: boolean;
  }>({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });
  const handleClose = () => {
    setNewState({ isPaneOpenLeft: true, isPaneOpen: true });
  };
  return (
    <>
      <div className="side-report-ctn mob-view">
        <div className="header mob-view">
          <div className="title">Your account</div>
          <IonImg
            src={dashboardIcon}
            className="icon-img"
            onClick={() =>
              setNewState({ isPaneOpenLeft: true, isPaneOpen: true })
            }
          />
        </div>
        <SlidingPane
          className="some-custom-class"
          closeIcon={<div></div>}
          isOpen={state.isPaneOpenLeft}
          title=""
          from="right"
          width="280px"
          onRequestClose={() =>
            setNewState({ isPaneOpenLeft: false, isPaneOpen: false })
          }
        >
          <div>
            <SideNav />
          </div>
          
      </SlidingPane>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    loggedIn: state.auth.loggedIn,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileRightSideNav);
