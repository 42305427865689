import React from 'react';
import './AccessRedirect.scss';
const AccessRedirect: React.FC = () => {
  return (
    <>
      <div className="ion-page access-redirect-page">
        International Casino is Under Maintenance.
      </div>
    </>
  );
};

export default AccessRedirect;
