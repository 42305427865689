import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import List from '@material-ui/core/List';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import './ReportsSideBar.scss';
import Button from '@material-ui/core/Button';
import { IonImg } from '@ionic/react';
// import userAvatar from '../../assets/images/img_avatar.png';
import { RootState } from '../../models/RootState';
import { connect } from 'react-redux';
import {
  getCurrencyTypeFromToken,
  logout,
  setOpenDepositModal,
  setOpenWithdrawModal,
} from '../../store';
import { CURRENCY_TYPE_FACTOR } from '../../constants/CurrencyTypeFactor';
import Deposit from '../../pages/Payment/Deposit';
import Modal from '../Modal/Modal';
import { isNotDemoUser } from '../../util/stringUtil';
import AGPAY_API from '../../api-services/agpay-api';
import { DomainConfig } from '../../models/DomainConfig';

type StoreProps = {
  balance: number;
  exposure: number;
  logout: Function;
  loggedIn: boolean;
  openDepositModal: boolean;
  openWithdrawModal: boolean;
  setOpenDepositModal: Function;
  setOpenWithdrawModal: Function;
  domainConfig: DomainConfig,
};
type Props = {};



const ReportsSideBar: React.FC<StoreProps> = (props) => {
  const {
    balance,
    exposure,
    logout,
    loggedIn,
    openDepositModal,
    openWithdrawModal,
    setOpenDepositModal,
    setOpenWithdrawModal,
    domainConfig
  } = props;
  const cFactor = CURRENCY_TYPE_FACTOR[getCurrencyTypeFromToken()];
  const [showDeposit, setShowDeposit] = useState<boolean>(false);
  const [showWithdrawal, setShowWithdrawal] = useState<boolean>(false);
  const [showPopover, setShowPopover] = useState<{
    open: boolean;
    event: Event | undefined;
  }>({
    open: false,
    event: undefined,
  });

  const logoutHandler = () => {
    logout();
    setShowPopover({ open: false, event: undefined });
  };

  const getPaymentProviders = async () => {
    const accountId = sessionStorage.getItem('aid');
    try {
      const response = await AGPAY_API.get(
        `/agpay/v2/payment-settings/accounts/${accountId}`,
        {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status === 200) {
        if (
          response?.data?.withdraw_provider_list &&
          response?.data?.withdraw_provider_list?.length > 0
        ) {
          setShowWithdrawal(true);
        } else {
          setShowWithdrawal(false);
        }

        if (
          response?.data?.deposit_provider_list &&
          response?.data?.deposit_provider_list?.length > 0
        ) {
          setShowDeposit(true);
        } else {
          setShowDeposit(false);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (loggedIn) {
      getPaymentProviders();
    }
  }, []);
  return (
    <>
      <div className="competitions-menu">
        <div className="username-ctn">
          <div className="username">
            <span className="username-span">
              {/* <IonImg className="user-avatar" src={userAvatar} alt="user" />{' '} */}
              {sessionStorage.getItem('username')}
            </span>
            {/* {ShowSelfRagistration[BRAND_NAME.toLocaleLowerCase()] ? (
              <>
                <span
                  className="deposit-btn"
                  onClick={(e) => {
                    setOpenDepositModal(true);
                  }}
                >
                  Deposit
                </span>
               
              </>
            ) : null} */}
          </div>
        </div>
        <hr className="border-bg" />
        <List
          component="nav"
          disablePadding
          className="competitions-menu-card-content"
        >
          <NavLink to="/profile" className="eventTypes-menu-nav-link">
            <ListItem button>
              <ListItemText primary="Profile" />
            </ListItem>
          </NavLink>
          {domainConfig.bonus && isNotDemoUser()  && <NavLink to="/referral" className="eventTypes-menu-nav-link">
            <ListItem button>
              <ListItemText primary="Referral" />
            </ListItem>
          </NavLink>}
          {/* {domainConfig.payments && showWithdrawal && isNotDemoUser()  && <NavLink to="/transaction/withdraw" className="eventTypes-menu-nav-link">
            <ListItem button>
              <ListItemText primary="withdrawl" />
            </ListItem>
          </NavLink>}
          {domainConfig.payments && showDeposit && isNotDemoUser()  && <NavLink to="/transaction/deposit" className="eventTypes-menu-nav-link">
            <ListItem button>
              <ListItemText primary="deposit" />
            </ListItem>
          </NavLink>} */}
          {/* <NavLink to="/home" className="eventTypes-menu-nav-link"> */}
          <ListItem
            button
            onClick={logoutHandler}
            className="eventTypes-menu-nav-link logout-btn"
          >
            <ListItemText primary="Logout" className="logout-btn" />
          </ListItem>
          {/* </NavLink> */}
        </List>

        {/* <Modal
          open={openDepositModal}
          closeHandler={() => setOpenDepositModal(false)}
          title="Deposit"
          size="md"
        >
          <Deposit />
        </Modal> */}
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    balance: state.auth.balanceSummary.balance,
    exposure: state.auth.balanceSummary.exposure,
    loggedIn: state.auth.loggedIn,
    openDepositModal: state.auth.openDepositModal,
    openWithdrawModal: state.auth.openWithdrawModal,
    domainConfig: state.common.domainConfig,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    logout: () => dispatch(logout()),
    setOpenDepositModal: (val) => dispatch(setOpenDepositModal(val)),
    setOpenWithdrawModal: (val) => dispatch(setOpenWithdrawModal(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsSideBar);
